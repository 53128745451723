/* that file is used by pdf service */

.wysiwyg {
  background: #ffffff;
  padding: 1rem;
}

.wysiwyg table[bold] {
  border: 1px solid black;
}

.wysiwyg table[bold] td {
  border: 1px solid black !important;
}

.wysiwyg table[bold] th {
  border: 1px solid black !important;
}

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4 {
  font-weight: normal !important;
  font-family: 'Gotham Bold' !important;
}

.wysiwyg h1,
.wysiwyg h1 > strong {
  font-size: 13pt !important;
  color: red !important;
  margin-bottom: 10pt !important;
}

.bermuda_mrc.wysiwyg h1,
.bermuda_mrc.wysiwyg h1 > strong {
  color: black !important;
}

.wysiwyg h2,
.wysiwyg h2 > strong {
  font-size: 13pt !important;
  margin-top: 0 !important;
  margin-bottom: 10pt !important;
}

.wysiwyg h3,
.wysiwyg h3 > strong {
  font-size: 11pt !important;
}

.wysiwyg h4,
.wysiwyg h4 > strong {
  font-size: 13pt !important;
  text-decoration: underline !important;
  text-transform: uppercase !important;
}

.eoc_binder h3 > strong,
.eoc_open_market h3 > strong {
  font-size: 15pt !important;
}

.wysiwyg strong {
  font-size: 9pt !important;
  font-weight: normal;
  font-family: 'Gotham Bold' !important;
}

.wysiwyg h1 > strong,
.wysiwyg h2 > strong,
.wysiwyg h3 > strong,
.wysiwyg h4 > strong {
  font-weight: bold;
}

.wysiwyg table,
.wysiwyg ol,
.wysiwyg ul,
.wysiwyg tr,
.wysiwyg td,
.wysiwyg p,
.wysiwyg.prose ol > li::before,
.wysiwyg.prose ul > li::before {
  font-size: 9pt !important;
  font-family: 'Gotham Book' !important;
}

.wysiwyg.prose td:first-child {
  padding-right: 0;
}

table.coverage-terms-excess-placement tr:first-child td:first-child {
  background-color: #404040;
}

table.coverage-terms-excess-placement tr:first-child td:first-child strong {
  color: #fff;
}

table.coverage-terms-excess-placement tr:nth-child(2) td {
  background-color: lightgray;
}

table.coverage-terms-primary-placement td {
  background-color: #f2f2f2;
}

table.coverage-terms-primary-placement td[colspan='4'] {
  background-color: #aaaaaa;
}

.eoc_binder tr.monofont p,
.eoc_binder tr[monofont] p,
.eoc_open_market tr.monofont p,
.eoc_open_market tr[monofont] p {
  font-family: 'Roboto Mono' !important;
}

td[rightalign] {
  text-align: right;
}

.prose td[hidetop='true'] {
  border-top: double 1px;
}

.prose td[hideright='true'] {
  border-right: double 1px;
}

.prose td[hidebottom='true'] {
  border-bottom: double 1px;
}

.prose td[hideleft='true'] {
  border-left: double 1px;
}

td:not([colwidth]),
td[colwidth=''] {
  max-width: 420px; /* calc(640px - 220px) */
}

td[colwidth='60'] {
  width: 60px;
}

td[colwidth='100'] {
  width: 100px;
}

td[colwidth='220'] {
  width: 220px;
}

td[colwidth='304'] {
  width: 304px;
}

td[colwidth='367'] {
  width: 367px;
}

/* BH250 */

tr.ed-250-bug-top > td[hidebottom='true'],
tr.ed-250-bug-top > td:not([hidebottom='true']) {
  border-bottom: unset;
}

tr.ed-250-bug-middle > td[hidetop='true'],
tr.ed-250-bug-middle > td:not([hidetop='true']) {
  border-top: unset;
}

tr.ed-250-bug-bottom > td[hidetop='true'],
tr.ed-250-bug-bottom > td:not([hidetop='true']) {
  border-top: unset;
}

/* BH250 lists */

.lg\:prose-lg ul.ed-250-bug-lists,
.lg\:prose-lg ol.ed-250-bug-lists {
  margin-top: 8px;
  margin-bottom: 8px;
}

.lg\:prose-lg ol.ed-250-bug-lists:last-of-type,
.lg\:prose-lg ul.ed-250-bug-lists:last-of-type {
  margin-bottom: 0;
}

.lg\:prose-lg .ed-250-bug-lists li:last-of-type {
  margin-bottom: 0;
}

.lg\:prose-lg ol.ed-250-bug-lists-first,
.lg\:prose-lg ul.ed-250-bug-lists-first,
.lg\:prose-lg ol.ed-250-bug-lists-top,
.lg\:prose-lg ul.ed-250-bug-lists-top {
  margin-top: 18px !important;
}

.lg\:prose-lg ol.ed-250-bug-lists-last,
.lg\:prose-lg ul.ed-250-bug-lists-last,
.lg\:prose-lg ol.ed-250-bug-lists-bottom,
.lg\:prose-lg ul.ed-250-bug-lists-bottom {
  margin-bottom: 0 !important;
}

/* */

.eoc_binder p > span,
.eoc_open_market p > span {
  font-size: 8pt !important;
}

table tr[locked='true'] > td:first-child {
  width: 20% !important;
  min-width: 20%;
  max-width: 20%;
}
