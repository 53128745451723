.multi-select-wrapper {
  flex-wrap: wrap;
}

.multi-select-wrapper > div {
  margin-bottom: 0.5rem;
}

.multi-select {
  min-height: 42px;
}

.tags-multi-select .multi-select {
  min-height: 40px;
  padding-top: 0.1rem;
}

.tags-multi-select .multi-select-wrapper > div {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin-top: 0.24rem;
  margin-bottom: 0.24rem;
}

.tags-multi-select .multi-select-wrapper label {
  padding-top: 0.4rem;
}

.admin .multi-select {
  width: 250px;
}

.item-tags-edit-mode .multi-select {
  min-height: 25px;
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
}

.item-tags-edit-mode .multi-select-wrapper > div {
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  font-weight: 500 !important;
  border-radius: 9999px !important;
}

.item-tags-edit-mode .multi-select-wrapper > div {
  margin-bottom: 0.4rem;
}

.item-tags-edit-mode .multi-select-wrapper > div:first-child {
  margin-top: 0 !important;
}

.item-tags-edit-mode .multi-select-wrapper > div:last-child {
  margin-bottom: 0 !important;
}
